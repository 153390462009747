require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');

import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { setEditorFlow } from './external/common/services/is-editor-flow';
import fetchExperimentsRequest from './external/common/store/experiments/experiments-request';

const bundleName = 'viewer-script';
const appData = { experimentsPromise: undefined };

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServicesApis,
  flowAPI,
) => {
  const appParams = initParams;
  const api = platformApis;

  if (process.env.NODE_ENV !== 'production' || wixCodeApi.location.query.debug === 'true') {
    console.log('initAppForPage', { appParams, api, wixCodeApi });
  }

  setEditorFlow();

  appData.experimentsPromise = fetchExperimentsRequest({
    wixCodeApi,
    baseUrls: appParams.baseUrls,
    bundleName,
  } as any);

  // will be passed as an `appData` for each controller
  return appData;
};
