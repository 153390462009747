import { isEditorFlow } from '../../../common/services/is-editor-flow';

const BUNDLE_NAME = 'wix-comments-controller';

export async function initWixCommentsController(
  { config, setProps, wixCodeApi, platformAPIs, type, compId },
  { staticsBaseUrl, isInIFrame },
) {
  let initializeCommentsController;
  if (isInIFrame || isEditorFlow()) {
    initializeCommentsController = (
      await import(/* webpackChunkName: "wix-comments-controller-chunk" */ './wix-comments-controller')
    ).default;
  } else {
    importScripts(staticsBaseUrl + `./${BUNDLE_NAME}.bundle.min.js`);
    initializeCommentsController = global[BUNDLE_NAME].default;
  }

  return initializeCommentsController({ config, setProps, wixCodeApi, platformAPIs, type, compId });
}
