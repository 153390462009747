import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { editorAdapter } from '../../external/common/controller/editor-adapter';
import { createArchiveController } from '../../external/archive-widget/controller';
import { createNoopController } from '../../external/viewer-script/controllers/createNoopController';

const createController: CreateControllerFn = async ({ appData, controllerConfig, flowAPI }) => {
  return editorAdapter({
    appData: appData || {},
    controllerConfig,
    flowAPI,
    createNoopController,
    createController: createArchiveController,
  });
};

export default createController;
