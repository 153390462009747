import { createControllerId, createLogger } from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { createReduxStore } from './create-redux-store';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { CATEGORY_MENU_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { isEditorFlow } from '../../common/services/is-editor-flow';

const isProduction = process.env.NODE_ENV === 'production';

export const createCategoryMenuController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { captureToSentry, fedopsLogger, fedopsAppLoadStarted, fedopsAppLoaded } = createMonitoring({
    origin: `app-worker-${CATEGORY_MENU_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    wixCodeApi,
    type,
    flowAPI,
  });

  fedopsAppLoadStarted();

  const language = getCurrentSiteLanguage(wixCodeApi);

  const log = createLogger(isDebug, isProduction);

  log('createCategoryMenuController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  let store;

  const pageReady = async () => {
    const _pageReady = async () => {
      log('createCategoryMenuController.pageReady -> start');

      simulateControllerError(wixCodeApi, 'category-menu.pageReady');

      const bundleName = 'category-menu-widget';

      initLazyActions({ staticsBaseUrl: appParams.baseUrls.staticsBaseUrl, isPreview, isEditor, isSSR });

      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        fedopsLogger,
        isEditor,
        isPreview,
        bundleName,
      });
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
      });
      const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });

      await initializeStoreBaseData({
        wixCodeApi,
        store,
        language,
        platformAPIs,
        config,
        context,
        bundleName,
        fedopsAppName: 'communities-blog-category-menu-widget',
        translationsName: 'category-menu-widget',
        appParams,
      });

      log('createCategoryMenuController.pageReady -> done');
      const state = store.getState();

      const stateVersions = getInitialStateVersions(state);
      const controllerId = createControllerId();

      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
        controllerId,
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToEditModeChange(store);
        listenToSettingsChange(store);
      }

      refreshDataOnLogin({ wixCodeApi, store, router: undefined });
      subscribeToChange(store, stateVersions, setProps, controllerId);
    };

    if (isEditorFlow()) {
      return _pageReady();
    }

    try {
      await _pageReady();
    } catch (error) {
      handleError({
        controller: CATEGORY_MENU_WIDGET_NAME,
        store,
        setProps,
        appParams,
        captureToSentry,
        isDebug,
        isSSR,
      })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams } }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
    },
  }).catch(console.error);
};
