import { get, once, noop } from 'lodash';
import { SENTRY_VIEWER_SCRIPT_DSN } from '../constants/sentry-viewer-script-dsn';
import getEnvironment from './get-environment';

const isProduction = process.env.NODE_ENV === 'production';

export const getEnvironmentTag = ({ origin, isSSR, isSeo }) =>
  `${origin}-${isSSR ? 'ssr' : 'csr'}${isSeo ? '-seo' : ''}${!isProduction ? '-dev' : ''}`;

export function createMonitoring({ appParams, wixCodeApi, platformAPIs, type, origin, flowAPI }) {
  const { fedOpsLoggerFactory, monitoring } = platformAPIs;
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);

  // TODO:YEF: keep only flowAPI once migrated to YEF.
  const sentry = flowAPI
    ? flowAPI.sentry
    : monitoring.createMonitor(SENTRY_VIEWER_SCRIPT_DSN, (data) => {
        let isSeo = false;
        try {
          isSeo = wixCodeApi.seo.isInSEO();
        } catch {}
        data.environment = getEnvironmentTag({ origin, isSSR, isSeo });
        return data;
      });

  // TODO:YEF: keep only flowAPI once migrated to YEF.
  const fedopsLogger = flowAPI
    ? flowAPI.fedops
    : type &&
      fedOpsLoggerFactory.getLoggerForWidget({
        appId: appParams.appDefinitionId,
        widgetId: type,
      });

  const fedopsAppLoadStarted = flowAPI ? noop : () => fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = flowAPI ? noop : once(() => fedopsLogger.appLoaded());

  const captureToSentry = (e) => {
    sentry.captureException(e);
    const errorId = sentry.lastEventId();
    if (isDebug || !isProduction) {
      console.log('error captured to Sentry:', errorId);
    }
    return errorId;
  };

  return {
    sentry,
    captureToSentry,
    fedopsLogger,
    fedopsAppLoadStarted,
    fedopsAppLoaded,
  };
}

export const normalizeError = (error) => {
  if (get(error, 'constructor.name') === 'Response' && error.stack) {
    return error.stack;
  }
  return error;
};
