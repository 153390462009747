import { EXPERIMENT_OOI_IN_EDITOR, EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';
import { get } from 'lodash';
import getEnvironment from '../services/get-environment';

const initializedControllers = {};

export const editorAdapter = async ({ appData, controllerConfig, flowAPI, createController, createNoopController }) => {
  const { wixCodeApi, appParams, type, compId } = controllerConfig;
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const controllerId = `${type}-${compId}`;

  if (isEditor || isPreview) {
    const experiments = await fetchExperiments(appData.experimentsPromise);
    const isIFrameEnabled = getIsIFrameEnabled({ isEditor, isPreview, experiments });
    const isWorker = getIsWorker();
    const _createNoopController = () => createNoopController({ wixCodeApi, appParams });

    if (isIFrameEnabled && (isWorker || initializedControllers[controllerId])) {
      return _createNoopController();
    }

    if (!isIFrameEnabled && isWorker && initializedControllers[controllerId]) {
      return _createNoopController();
    }

    if (isIFrameEnabled && !isWorker) {
      initWindowResize();
    }
  }

  initializedControllers[controllerId] = true;
  return createController(controllerConfig, undefined, appData, flowAPI);
};

async function fetchExperiments(experimentsPromise) {
  try {
    const body = await experimentsPromise;
    return get(body, 'values', {});
  } catch (e) {}

  return {};
}

function getIsIFrameEnabled({ isEditor, isPreview, experiments = {} }) {
  const isPreviewIFrame = isPreview && experiments[EXPERIMENT_PROD_OOI_EDITOR] === 'true';
  const isEditorIFrame = isEditor && experiments[EXPERIMENT_OOI_IN_EDITOR] !== 'true';

  return isPreviewIFrame || isEditorIFrame;
}

function getIsWorker() {
  return typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
}

function initWindowResize() {
  if (typeof window === 'undefined') {
    return;
  }

  const windowResizeId = 'communities-window-resize-script';
  if (document.getElementById(windowResizeId)) {
    return;
  }

  window.wixTpaWindowResize = {
    elSelector: '#root',
    enableViewportFix: false,
  };

  const s = document.createElement('script');
  s.setAttribute(
    'src',
    'https://static.parastorage.com/services/communities-window-resize/1.4.0/communities-window-resize.min.js',
  );
  s.async = false;
  s.id = windowResizeId;
  document.querySelector('body').appendChild(s);
}
