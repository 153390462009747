import { isEditorFlow } from '../../services/is-editor-flow';

const DUPLEXER_BUNDLE_NAME = 'wix-duplexer';

export default async function createDuplexerConnection({ duplexerUrl, blogApiBaseUrl, staticsBaseUrl, instance }) {
  let Duplexer;

  if (isEditorFlow()) {
    ({ Duplexer } = await import(/* webpackChunkName: "wix-duplexer-chunk" */ '../../../viewer-script/wix-duplexer'));
  } else {
    importScripts(staticsBaseUrl + `./${DUPLEXER_BUNDLE_NAME}.bundle.min.js`);
    Duplexer = global[DUPLEXER_BUNDLE_NAME].Duplexer;
  }

  const duplexerClient = new Duplexer(duplexerUrl);
  return duplexerClient.connect({
    apiKey: '8dd942aa-6255-4bb0-b004-8bd6cf2f6b35', // HACK
    auth: {
      url: `${blogApiBaseUrl}/_api/duplexer/auth`,
      onBeforeRequest: () => ({
        headers: {
          instance,
        },
      }),
    },
  });
}
